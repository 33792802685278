import config from '@/config/default'

/**
 * 全局变量
 */
const property = {
  install: (Vue: any, options: any) => {
    Vue.prototype.$uploadImg = config.uploadImg
    Vue.prototype.$uploadImgUrl = config.uploadImgUrl
  }
}

export default property;