import router from "@/router";

const initBaiduBurialPoint = () => {
    const mode = process.env.NODE_ENV;
    if (mode !== "production") {
        return
    }
    (function() {
      const hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?f1250d2b6ee5e0df541b64f51e69cfe4";
      const s = document.getElementsByTagName("script")[0]; 
      if (s.parentNode) {
        s.parentNode.insertBefore(hm, s);
      }
    })();
}

initBaiduBurialPoint();



