
import { Component, Vue } from "vue-property-decorator";
import { login, adminInfo } from "@/api/login";
import { Decrypt, Encrypt } from "@/utils/secret";
const packageInfo = require("../../../package.json");

@Component
export default class Login extends Vue {
  username: string = "";
  password: string = "";
  savePassword: boolean = false;
  passwordType: string = "password";
  dialogVisible = false;
  loading: boolean = false;
  version: string = packageInfo.version;

  /**
   * 生命周期
   */
  mounted() {
    if (localStorage.getItem("savePassword")) {
      let s = localStorage.getItem("savePassword");
      this.savePassword = s == "true" ? true : false;
    }
    if (localStorage.getItem("yysj_username")) {
      this.username = String(localStorage.getItem("yysj_username"));
    }
    if (localStorage.getItem("yysj_pd")) {
      this.password = Decrypt(String(localStorage.getItem("yysj_pd")));
    }
  }

  /**
   * methods
   */
  confirm(): void {
    if (this.username.trim().length > 0 && this.password.trim().length > 5) {
      this.loading = true;
      login({
        username: this.username,
        password: this.password,
      })
        .then(async (res: any) => {
          this.$store.dispatch("setToken", res.data.tokenHead + res.data.token);
          localStorage.setItem("yysj_username", this.username);
          this.changeSavePassword();
          let ad = await adminInfo().then((res) => {
            this.$store.dispatch("setUserinfo", res.data);
          });
          this.loading = false;
           this.$message.success("登录成功");
          this.$router.replace("/home");
        })
        .catch((err: any) => {
          console.log(err);
          this.$message.error("账户异常，请联系连锁技术专员");
          this.loading = false;
        });
    } else {
      this.$message.error("账户密码有误");
    }
  }

  // 保存密码
  changeSavePassword(): void {
    let s: string = "" + this.savePassword;
    localStorage.setItem("savePassword", s);
    if (this.savePassword) {
      localStorage.setItem("yysj_pd", Encrypt(this.password));
    } else {
      localStorage.removeItem("yysj_pd");
    }
  }

  // 查看密码
  viewPassword(): void {
    this.passwordType = this.passwordType == "password" ? "text" : "password";
  }
}
