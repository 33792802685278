
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class TablePagination extends Vue {
  total: number = 0; // 总数量
  pageSize: number = 10; // 单页数量
  currentPage: number = 1; // 当前页数
  pageSizes: Array<number> = [5, 10, 20, 30, 40] // 当页数量可选项
  itemTotal: number = 0;

  /**
   * 生命周期
   */
  created() {
    this.$store.dispatch('setLoading', true)
  }

  /**
   * methods
   */
  getData() { }
  // 每页条数
  handleSizeChange(val: number) {
    this.pageSize = val;
    this.getData();
  }
  // 修改单页数量
  handleCurrentChange(val: number) {
    this.currentPage = val;
    this.getData();
  }
  // 表头样式
  headerCellStyle() {
    return {
      background: '#F8F9FA', color: '#1A1A1C', textAlign: 'center'
    }
  }
  resetCurrent() {
    this.currentPage = 1;
  }
  // 列表样式
  cellStyle() {
    return {
      textAlign: 'center'
    }
  }
}

