import { EnumerateEnum } from "@/enum/enum.enum";

export const getCatchByKey = (key: EnumerateEnum) => {
    const data = localStorage.getItem(key);
    if (data) return JSON.parse(data);
    return "";
}

export const setCatchByKey = (key: EnumerateEnum, data) => {
    localStorage.setItem(key, JSON.stringify(data));
}
