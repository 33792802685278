// README: 类似枚举，公共数据的接口放这里
// import { service } from '@/ajax/request'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import config from "@/config/default";

export const getRegionList = () => {
    const timestamp = dayjs().valueOf();
    return axios({
        url: `${config.uploadImgUrl}system_info/area.json?time=${timestamp}`,
        method: 'GET',
    })
}