/**
 * 公用状态
 */
const index = {
  state: {
    loading: false,
    isCollapse:false
  },
  mutations: {
    SET_LOADING(state: any, val: any) {
      state.loading = val
    },
    SET_COLLAPSE(state: any, val: any) {
      state.isCollapse = val
    },
  },
  actions: {
    setLoading: ({ commit }: any, val: any) => {
      commit('SET_LOADING', val)
    },
    setCollapse: ({ commit }: any, val: any) => {
      commit('SET_COLLAPSE', val)
    }
  },
  getters: {
    loading: (state: any) => state.loading,
    isCollapse: (state: any) => state.isCollapse
  }
}

export default index