// const domainDev = 'http://test.yangbocheng.com/admin' // 开发环境域名
const domainDev = 'https://test.yyznmd.cn/admin'
// const domainDev = 'http://www.yangbocheng.com/admin'
// const domainDev = '/api'
// const domainDev = 'http://114.132.155.54:8080'

// const domainDev = 'https://www.yyznmd.cn/admin'

const domainProduct = '/admin' // 生产环境域名

export default {
  baseUrl: process.env.NODE_ENV === "production" ? domainProduct : domainDev, // 域名
  uploadImg: process.env.NODE_ENV === "production" ? domainProduct + '/sys/upload/' : domainDev + '/sys/upload/',
  uploadImgUrl: process.env.NODE_ENV === "production"
    ? "https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/"
    : "https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/"  // 区分开发模式和发布模式
}