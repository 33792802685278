
import { Component, Vue, Mixins, Prop, Watch } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import { Menu, EditableTabs } from "@/types/views";
import { Userinfo } from "@/types/store";
// 工具
import print from "@/utils/print";

@Component
export default class Submenu extends Vue {
  @Prop() descripbeName!: string;
  @Prop() editableTabsValue!: string;
  @Prop() isCollapse!: boolean;
  @Prop() editableTabs!: Array<EditableTabs>;

  @Watch('isCollapse')
  setCollapse(){
    this.$store.dispatch('setCollapse',this.isCollapse)
  }

  /**
   * computed计算
   */
  get userinfo(): Userinfo {
    return this.$store.getters.userinfo;
  }
  get loading() {
    return this.$store.getters.loading;
  }
}
