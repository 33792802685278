import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

/**
 * require styles
 * 富文本样式
 */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)