/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Notification } from 'element-ui';
import config from '@/config/default';
import store from '@/store/index';
import route from '@/router/index';
import qs from 'qs'
import { AxiosInstance, AxiosRequestConfigs } from '@/types/ajax/request'
import { trimObj } from "@/utils/common";


/**
 * 创建axios实例
 */
export const service: AxiosInstance = axios.create({
  baseURL: "/admin",
  timeout: 15000, // 请求超时时间
  withCredentials: true,  // 凭证,是否带上cookie
})


/**
 * 请求前拦截
 */
service.interceptors.request.use(
  (config: AxiosRequestConfigs) => {
    config.data = trimObj(config.data);
    config.params = trimObj(config.params);
    if (config.method?.toUpperCase() == 'POST') {
      if(config.type == 'form'){
        config.data = qs.stringify(config.params);
      }else if(config.type == 'newForm'){
        config.data = qs.stringify(config.params,{ skipNulls: true});
      }else if(config.type == 'string'){
        config.data  = config.params;
      }else{
        config.data = config.params
      }
      config.params = null
    }
    config.headers = {
      ...config.headers,
      'Content-Type': (config.type == 'form' || config.type == 'newForm' ) ? 'application/x-www-form-urlencoded' : 'application/json;charset=UTF-8',
      'Authorization': store.getters.token
    }
    return config
  },
  (error: AxiosRequestConfig) => {
    return Promise.reject(error);
  }
);


/**
 * 响应拦截器
 */
service.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 200) {
      if(response.data.constructor == Blob){
        return Promise.resolve(response.data)
      }
      if (response.data.code == 200) {
        return Promise.resolve(response.data)
      } else if (response.data.code == 401) { // 未登录或登录过期
        Notification({
          title: `警告`,
          message: response.data.message,
          type: 'error'
        });
        route.replace('/login');
        return Promise.reject(response);
      } else {
        Notification({
          title: `警告`,
          message: response.data.message,
          type: 'error'
        });
        return Promise.reject(response);
      }
    }
    Notification({
      title: `警告`,
      message: `接收数据失败`,
      type: 'error'
    });
    return Promise.reject(response);
  },
  // 响应状态码不是200的情况    
  (error: any) => {
    if (error && error.response && error.response.status) {
      Notification({
        title: `${error.response.status}警告`,
        message: `数据请求失败`,
        type: 'error'
      });
      return Promise.reject(error.response);
    } else {
      Notification({
        title: `警告`,
        message: `网络链接失败${error}`,
        type: 'error'
      });
      return Promise.reject();
    }
  }
);
