
/**
 * 产品
 */
const product = {
  state: {
    provinces: [], // 省份
  },
  mutations: {
    SAVE_PROVINCES(state: any, val: any) {
      state.provinces = val
    },
  },
  actions: {
    saveProvinces: ({ commit }: any, val: any) => {
      commit('SAVE_PROVINCES', val)
    }
  },
  getters: {
    provinces: (state: any) => state.provinces
  }
}

export default product