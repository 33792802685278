import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import './plugins/editor'
import './plugins/filter'
import './plugins/directive'
// 百度埋点
import './plugins/baiduBurialPoint'
import axios from 'axios'
import VueAxios from 'vue-axios'
import property from '../vue-property'
import VueQuillEditor from 'vue-quill-editor'

import { setRegionData } from "@/utils/cityCode"

// iconfont
import "@/assets/iconfont/iconfont.css";

/**
 * require styles
 * 富文本样式
 */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)
Vue.use(VueAxios, axios)
Vue.use(property)  // 全局变量
Vue.config.productionTip = false

// 刷新页面就重新拉取省市区
setRegionData();
	
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
