// 类型
import { Userinfo } from '@/types/store/index'
import print from '@/utils/print'
import copy from '@/utils/deepCopy'

// 类型初始化
const UserinfoParams: Userinfo | null = {
  icon: "",
  menus: [],
  roles: [],
  username: ""
}
// 初始化数据
let ybc_userinfo: any = localStorage.getItem('ybc_userInfo')

// 匹配权限
const permission = (value: Array<Userinfo>): Array<Userinfo> => {
  let menu: Array<any> = [];
  // 1. 第一步过滤掉hidden=0的菜单
  let data = copy(value);
  //data = data.filter((e: any) => e.hidden != 0);
  // 2. 第二步创建父级
  let parentMenu: Array<any> = [];
  parentMenu = data.reduce((pre: any, cur: any) => {
    pre.length == 0
      ? pre.push({
        title: "其他",
        icon: "",
        parentId: 0,
        level: 0,
        name: "empty",
        children: [],
      }) : null;
    if (cur.parentId == 0) {
      // 为父级
      pre.push({
        ...cur,
        children: [],
      });
    }
    return pre;
  }, []);

  // 3. 把子节点挂到父节点下面
  menu = data.reduce((pre: any, cur: any) => {
    // 过滤出子路由
    if (cur.parentId > 0) {
      // 寻找所归属的父级
      let index = pre.findIndex((f: any) => {
        return f.id == cur.parentId
      });
      if (index > -1) {
        // 存在
        pre[index].children.push(cur);
      } else {
        // 不存在
        pre[0].children.push(cur);
      }
    }
    return pre;
  }, parentMenu);

  // 首页是单独的，把其他移到末尾
  menu.unshift({
    title: "首页",
    icon: "el-icon-s-home",
    parentId: 0,
    level: 0,
    name: "home",
    children: [],
  });
  let item: any = menu.splice(1, 1);
  // 4. ”其他“这个菜单分类如果有子菜单则显示，没有则隐藏
  item[0]?.children.length > 0 ? menu.splice(menu.length, 0, item[0]) : null
  // 5. 对菜单进行排序
  menu = menu.sort((a: any, b: any) => b.sort - a.sort);
  menu.forEach((item) => {
    item.children = item.children?.sort((a: any, b: any) => b.sort - a.sort)
  })
  return menu
}

// 状态
const user = {
  state: {
    userinfo: JSON.parse(ybc_userinfo) || UserinfoParams,
    token: localStorage.getItem('yysj_token')
  },
  mutations: {
    // 设置用户信息
    SET_USER_INFO(state: any, value: Userinfo): void {
      value.menus = permission(value.menus)
      localStorage.setItem(`ybc_userInfo`, JSON.stringify(value))
      state.userinfo = value
    },
    // 保存token
    SET_TOKEN(state: any, value: string): void {
      localStorage.setItem("yysj_token", value);
      state.token = value
    },
  },
  actions: {
    setUserinfo: ({ commit }: any, value: Userinfo) => {
      commit('SET_USER_INFO', value)
    },
    setToken: ({ commit }: any, value: string) => {
      commit('SET_TOKEN', value)
    },
  },
  getters: {
    userinfo: (state: any) => state.userinfo,
    token: (state: any) => state.token
  },
}

export default user