import { getRegionList } from "@/api/common";
import { setCatchByKey, getCatchByKey } from "./catch";
import { EnumerateEnum } from "@/enum/enum.enum";

export const setRegionData = () => {
  getRegionList().then((res) => {
    const data = res.data;
    setCatchByKey(EnumerateEnum.yyRegion, data);
  }).catch((err) => {
    setCatchByKey(EnumerateEnum.yyRegion, []);
  });
}

/**
 * @description 缓存中拿省市区 
 * @returns Promise<Array<any>>
 */
export const getRegionData = () => {
  // 先判断ls 里面有没有 yy-region
  const regionData = getCatchByKey(EnumerateEnum.yyRegion);
  return new Promise((resolve) => {
    if (regionData) {
      return resolve(regionData);
    }
    getRegionList().then((res) => {
      setCatchByKey(EnumerateEnum.yyRegion, res.data);
      return resolve(res.data);
    }).catch((err) => {
      return resolve([]);
    });
  })
}