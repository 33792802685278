
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class Loading extends Vue {
  created() {
    this.showLoading()
  }
  showLoading() {
    this.$store.dispatch('setLoading', true)
  }
  hideLoading() {
    this.$store.dispatch('setLoading', false)
  }

  get loading() {
    return this.$store.getters.loading;
  }
}

