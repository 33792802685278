import Vue from "vue";
import { DiagnosesTypeEnum } from "@/enum/common.enum";

Vue.filter('gender', (value: number) => {
    if (!value) return '未知';
    if (value == 1) {
        return "男"
    } else if (value == 2) {
        return "女"
    } else {
        return "未知"
    }
  })
  Vue.filter('formatMoney', (number: number | string = 0) => {
    if (!number) number = 0;
    var arr = (number + '').split('.');
    var int = arr[0].split('');
    var fraction = arr[1] || '';
    var r = '';
    int.reverse().forEach(function (v, i) {
      if (i !== 0 && i % 3 === 0) {
        r = v + ',' + r;
      } else {
        r = v + r;
      }
    });
    if (!!fraction) {
      return r + "." + fraction;
    } else {
      return r;
    }
  })

Vue.filter("diagnosesTypeText", (typeId) => {
  if (typeId == DiagnosesTypeEnum.Lung) {
    return "肺功能检测"
  } else if (typeId == DiagnosesTypeEnum.Taking) {
    return "脉诊"
  } else if (typeId == DiagnosesTypeEnum.Tongue) {
    return "舌诊"
  } else if (typeId == DiagnosesTypeEnum.Phy) {
    return "体质辨识"
  } else if (typeId == DiagnosesTypeEnum.Health) {
    return "健康测评"
  } else {
    return "面诊"
  }
})

Vue.filter("storeTypeText", (storeType) => {
  const storeTypeConfig = {
    30001: "自营店",
    30002: "联营店",
    30003: "加盟店",
    30004: "合作店",
    30005: "品牌商合作店",
    30006: "品牌商",
    30007: "其它",
  }
  return storeTypeConfig[storeType]
})