
import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
import Loading from "@/mixins/loading";
import tablePagination from "@/mixins/tablePagination";
import { Menu } from "@/types/views";

@Component
export default class Submenu extends Mixins(Loading, tablePagination) {
  @Prop() activeMenu!: string;
  @Prop() isCollapse!: boolean;
  @Prop() menu!: Array<Menu>;
}
