import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import common from './modules/common'
import user from './modules/user'
import product from './modules/product'

const store = new Vuex.Store({
  modules: {
    common,
    user,
    product
  }
})

export default store;