import { service } from '@/ajax/request'
import { Login } from '@/types/api/login'
import { AxiosPromise } from 'axios'

// 登录
export const login = (params: Login): AxiosPromise => (
  service({
    url: '/admin/login',
    method: 'POST',
    params
  })
)

// 登出
export const loginOut = (): AxiosPromise => (
  service({
    url: '/admin/logout',
    method: 'POST'
  })
)

// 获取当前登录用户信息
export const adminInfo = (): AxiosPromise => (
  service({
    url: '/admin/info',
    method: 'get',
    type: 'form'
  })
)