import { ElPopconfirm } from "element-ui/types/popconfirm"

const copy = (data: any) => {
  try {
    return JSON.parse(JSON.stringify(data))
  } catch (error) {
    throw new Error(`深拷贝错误： ${error}`)
  }
}

export default copy