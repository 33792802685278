import Big from 'big.js';


export const toYuanFixed = (value: string | number) => {
  value = (value as number) / 100;
  let temp = new Big(value)
  return temp.toFixed(2);
}
export const formatMoney = function(number: number | string) {
  number = Number(number).toFixed(2);
  var arr = (number + '').split('.');
  var int = arr[0].split('');
  var fraction = arr[1] || '';
  var r = '';
  int.reverse().forEach(function (v, i) {
    if (i !== 0 && i % 3 === 0) {
      r = v + ',' + r;
    } else {
      r = v + r;
    }
  });
  if (!!fraction) {
    return r + "." + fraction;
  } else {
    return r;
  }
}

/**
 * @description 将对象里面的 null undefined 转成想要的，避免操作失误
 * @param {*} data 
 * @param {*} key 对象的key 
 * @param {*} objectKeys 需要将null undefined 转成{} 的key
 * @param {*} arrayKeys 需要将null undefined 转成[] 的key
 * @returns {*} formatData
 */
export const handleBackendDataWithDefaults = (data: any, objectKeys = [], arrayKeys = [], key = "") : any => {
  console.log(key, objectKeys, arrayKeys)
  if (data === null || data === undefined) {
      console.log("key", key)
    // 设置默认值
    if (key && objectKeys.includes(key as never)) return {}; 
    if (key && arrayKeys.includes(key as never)) return []; 
    return "";
  } else if (Array.isArray(data)) {
    // 如果是数组，递归处理每个元素，并返回空数组作为默认值
    return data.map(item => handleBackendDataWithDefaults(item));
  } else if (typeof data === 'object') {
    // 如果是对象，递归处理每个属性的值，并返回空对象作为默认值
    const result = {} as any;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        result[key] = handleBackendDataWithDefaults(data[key], objectKeys = [], arrayKeys = [], key);
      }
    }
    return result;
  } else {
    // 其他情况，直接返回数据
    return data;
  }
}

export const truncateDecimal = (number: number | string, decimalPlaces = 2) => {
  const factor = Math.pow(10, decimalPlaces);
  number = !number ? 0 : number;
  return Math.floor(Number(number) * factor) / factor;
}

export const trimObj = (obj: any) => {
  if ((!Array.isArray(obj) && typeof obj != "object") || (!obj && typeof obj != "undefined" && obj != 0)) return obj;
  if (obj instanceof FormData) return obj;
  return Object.keys(obj).reduce(
    function (acc: any, key: string) {
      acc[key.trim()] = typeof obj[key] == "string" ? obj[key].trim() : trimObj(obj[key]);
      return acc;
    },
    Array.isArray(obj) ? [] : {}
  );
}

export const formatTextarea = (content) => {
  if (!content) return "";
  return content.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;'); 
}

export const formatNumber = (value) => {
  if (!value && value !== 0) return '';
  return truncateDecimal(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,',');
};

export const parseNumber = (value) => {
  return parseFloat(value.replace(/,/g,''));
};