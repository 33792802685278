import Vue from "vue";
import { on,off,getStyle } from 'element-ui/src/utils/dom';
import { update } from "@/api/tongue";
import { truncateDecimal } from "@/utils/common";

Vue.directive('ak-tooltip-auto-show',{
  inserted: function (el,binding,vnode) {
    el.addEventListener('mouseenter',function (e) {
      let defalutSilent = !!Vue.config.silent;
      Vue.config.silent = true;
      // @ts-ignore
      vnode.componentInstance.disabled = true;
      const range = document.createRange();
      range.setStart(el,0);
      range.setEnd(el,el.childNodes.length);
      const rangeWidth = Math.round(range.getBoundingClientRect().width);
      const padding = (parseInt(getStyle(el,'paddingLeft'),10) || 0) + (parseInt(getStyle(el,'paddingRight'),10) || 0);
      if (rangeWidth + padding > el.offsetWidth || el.scrollWidth > el.offsetWidth) {
        // @ts-ignore
        vnode.componentInstance.disabled = false;
      }
      Vue.config.silent = defalutSilent;
    });
  }
});

const formatNumber = (value) => {
  if (!value) return '';
  return truncateDecimal(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,',');
};
function countOccurrences(string,char) {
  // 使用正则表达式匹配所有的字符，并返回匹配结果数组的长度
  const regex = new RegExp(char,'g');
  const matches = string.match(regex);
  return matches ? matches.length : 0;
}
const currencyFormat = {
  bind(el,binding,vnode) {
    const formatNumber = (value) => {
      if (!value && value !== 0) return '';
      return truncateDecimal(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,',');
    };

    const parseNumber = (value) => {
      return parseFloat(value.replace(/,/g,''));
    };

    const setModelValue = (value) => {
      setTimeout(() => {
        if (vnode.data.model) vnode.data.model.callback(value)
      },0)
    }

    const handleInput = (event) => {

      const input = event.target.value.replace(/,/g,''); // 移除所有的逗号
      let parsedValue = parseFloat(input);

      const cursorPosition = event.target.selectionStart; // 记录光标位置
      const newPosition = cursorPosition + (input.length - event.target.value.length);

      if (input > 10 && event.target.value?.indexOf(".") <= -1) {
        parsedValue = truncateDecimal(parsedValue / 100);
      }

      if (!isNaN(parsedValue)) {
        if (binding.value.max !== undefined && parsedValue > binding.value.max) {
          event.target.value = formatNumber(binding.value.max);
        } else if (binding.value.min !== undefined && parsedValue < binding.value.min) {
          event.target.value = formatNumber(binding.value.min);
        } else {
          event.target.value = formatNumber(parsedValue);
        }
      } else {
        event.target.value = '';
      }

      const charCount = countOccurrences(event.target.value,",")

      // 恢复光标位置
      if (newPosition !== null) {
        event.target.setSelectionRange(newPosition + charCount,newPosition + charCount);
      }

      setModelValue(event.target.value)
    };

    const handleBlur = (event) => {
      const input = event.target.value.replace(/,/g,''); // 移除所有的逗号
      const parsedValue = parseFloat(input);

      if (!isNaN(parsedValue)) {
        event.target.value = formatNumber(parsedValue);
      } else {
        event.target.value = '';
      }
      setModelValue(event.target.value)
    };

    const handleFocus = (event) => {
      event.target.value = event.target.value.replace(/,/g,''); // 去除格式化，方便用户编辑
    };

    const el2 = el.querySelector(".el-input__inner")
    el2.addEventListener('input',handleInput);
    el2.addEventListener('blur', handleBlur);
    el2.addEventListener('focus',handleFocus);

    // 保存处理函数的引用，方便解绑
    el2._handleInput = handleInput;
    el2._handleBlur = handleBlur;
    el2._handleFocus = handleFocus;
  },

  inserted(el,binding,vnode){
    const el2 = el.querySelector(".el-input__inner")
    const input =el2.value?.replace(/,/g,'') || 0; // 移除所有的逗号
    const parsedValue = parseFloat(input);

    if (!isNaN(parsedValue)) {
      el2.value = formatNumber(parsedValue);
    } else {
      el2.value = '';
    }
  },

  componentUpdated(el,binding,vnode) {
  },

  updated(el,binding,vnode) {
  },
  unbind(el) {
    const el2 = el.querySelector(".el-input__inner")
    // 移除事件监听器，避免内存泄漏和重复触发问题
    el2.removeEventListener('input',el2._handleInput);
    el2.removeEventListener('blur',el2._handleBlur);
    el2.removeEventListener('focus',el2._handleFocus);

    // 清空保存的处理函数引用
    el2._handleInput = null;
    el2._handleBlur = null;
    el2._handleFocus = null;
  },

}

Vue.directive('currency-format',currencyFormat);


